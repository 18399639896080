(function(wnd){
    function contactForm(container, params) {
        try {
            if (!container) {
                throw 'Container is required!';
            }

            this.container = container;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch(e) {
            console.error(e);
        }
    }

    contactForm.prototype = {
        container: undefined,
        form:undefined,
        button: undefined,
        notyf:undefined,
        sendedForm: false,
        sendToGoogle: false,
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.button = this.container.find('.send-contact-form');
            this.notyf = new Notyf({
                delay: 5000,
            });
            this.form = this.container.find('form');
        },
        addDOMEvents: function() {
            var _self = this;
            if (this.button && _self.form && _self.form.length == 1) {                
                this.button.click(function() {
                    _self.sendForm();
                });
            } 

            this.container.find('.phone-field').keypress(function(e){
                var code = typeof e.which !== 'undefined' && e.which ? e.which : e.keyCode;
                var exeptions = [37, 39];
                var key = typeof e.key !== 'undefined' ? e.key : undefined;

                if (key && (key == "'" || key == "%")) {
                    return false;
                }

                if (exeptions.indexOf(code) >= 0) {
                    return true;
                }
                
                if (code > 31 && (code < 48 || code > 57)) {
                    return false;
                }
        
                return true;
            });

            this.container.find('[data-purpose="back-to-form"]').click(function(){
                _self.container.removeClass('success-sent');
            });
        },
        setParams: function(params) {
            console.log(params);
        },
        sendForm: function() {
            var _self = this;
            if (!this.sendedForm) {
                _self.sendedForm = true;
                
                $.ajax({
                    url:base_url+'ajax/sendContactForm',
                    dataType:'json',
                    type:'post',
                    data:_self.form.serialize(),
                    beforeSend: function() {
                        _self.container.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (response.success) {
                            _self.sendEvent(response.success);
                            _self.showConfirmContent();
                            _self.resetForm();
                        } else {
                            if (typeof response.errors == 'object') {
                                $.each(response.errors, function(key, value) {
                                    if (value.trim() !== '') {
                                        _self.notyf.alert(value);
                                    }

                                    var input = _self.container.find('[name="'+key+'"]');
                                    if (input.length > 0) {
                                        input.addClass('has-error');
                                    }
                                });
                            }
                        }
                    },
                    complete: function(){
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 1000);
                    }
                });
            }
        },
        showConfirmContent: function() {
            this.container.addClass('success-sent');
        },
        resetForm: function() {
            this.form.find('.field').each(function(){
                $(this).val('');
            });

            this.form.find('input[type="checkbox"]').each(function(){
                $(this).prop('checked', false);
            });
        },
        sendEvent: function(success) {
            var evt_name = 'jelentkezés';
            if(this.form.find('[name="product_name"]').length > 0) {
                evt_name = 'termék érdeklődés';
            }
            
            if (evt_name !== '') {
                var action = (success ? 'Sikeres' : 'Sikertelen')+' '+evt_name;
                var field = this.form.find('input[name="name"]');
                var tag = '';
                if (field.length > 0) {
                    tag = field.val();
                }
                
                sendAnalyticsEvent(action, 'Lead', tag);

                if (success) {
                    sendAnalyticsEvent('conversion', {
                        'send_to': 'AW-529173037/mRrICPSyhOUBEK2UqvwB'
                    });
                }
            }
        }
    }


    wnd.contactForm = contactForm;
})(window);
