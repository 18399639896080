window.loaded = false;
var windowWidth = 0;
var headerSlider = undefined;

$(window).on('load', function(){
	window.loaded = true;
});

var headerBorder = undefined;
var contactMap = undefined;

$(document).ready(function() {
	/* MOBILE */
	//create js hook links
	windowWidth = window.innerWidth;

	$('.mailtolink').click(function(e) {
		handleMailtoLink($(this));
	});



	$('[data-purpose="video-item"]').click(function(){
		openVideoRemodal($(this).data('youtube-id'));
	});

	$('[data-purpose="navigation-link"]').each(function(){
		setNavigationUrl($(this));
	});
	
	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('popup-target');
		if ($(this).data('popup-target')) {
			var item = $('[data-remodal-id="' + $(this).data('popup-target') + '"]');
			if (item.length > 0) {
				item.remodal().open();
			}
		}
	});
	
	if( $('.cookie-bar').length > 0 ) {

		$('.cookie-bar .button').click(function(){
			$('.cookie-bar').addClass('closed');

			$.ajax({
				url:ajax_controller+'setCookieConfirmOld',
				dataType:'json',
				type:'post',
				data:{cookie:1},
				success: function(){}
			});
		});
	}

	$('[data-purpose="remodal-close"]').click(function(){
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function(){
		var target = $(this).data('target');
		var item = $(target);
		var timeout = $(this).data('timeout');
		if (isNaN(timeout)) {
			timeout = 400;
		}
		
		if (item.length > 0) {
			$('html, body').animate({scrollTop: item.offset().top}, timeout);
		}
	});

	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('target');
		if (target) {
			openRemodalContent(target);
		}
	});

	$('[data-purpose="privacy-confirm-button"]').click(function(){
		var type = $(this).data('type');
		$('#privacyChk').prop('checked', type == 'accept');
		$('[data-remodal-id="privacyRemodal"]').remodal().close();
	});

	
	if (typeof site_id !== 'undefined') {
		if (site_id == 1) {	
			initSlider();
		} else if (site_id == 7) {
			initContactMap();
		}
	}

	if (typeof articleGalleryItems !== 'undefined') {
		$('.article-gallery-header-item').click(function(){
			openArticleGallery($(this));
		});
	}

	if (typeof galleryItems !== 'undefined') {
		$('[data-purpose="gallery-item"]').click(function(){
			openGallery($(this));
		});
	}

	$('.accordion-list').each(function(ind, el) {
		var options = {};
		var th = $(el);

		if (th.data('single-active-item') != undefined) options.singleActiveItem = true;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
		if (th.data('from-width') != undefined) options.fromWidth = parseInt(th.data('from-width'));
		
		new Accordion(th, options);
  	});
});

$(window).on('load', function(){
	$('[data-purpose="before-after-swipe"]').twentytwenty({
		no_overlay: true
	});
});

function openRemodalContent(remodal_id) {
	var tmp = $('[data-remodal-id="'+remodal_id+'"]');
	if(tmp.length > 0){
	  tmp.remodal().open();
	}
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function sendAnalyticsEvent(action, category, tag) {
	if (typeof gtag !== 'undefined' && action && category) {
		if (typeof category === 'object') {
			var evtData = category;
		} else {
			var evtData = {
				event_category: category,
			};

			if (tag && tag.trim() != '') {
				evtData.event_label = tag;
			}
		}
		
		gtag('event', action, evtData);
	}
	else if(typeof ga != 'undefined' && action && category){
		if(tag && tag.trim() != ''){
			ga('send', 'event', action, category, tag);
		}else{
			ga('send', 'event', action, category);
		}
	}
}

function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}


function loadJs(url, implementationCode){
    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    document.body.appendChild(scriptTag);
};

function setNavigationUrl(item) {
	var address = item.data('address');
	
	if (address) {
		var protocol = 'google';
		var ua = navigator.userAgent.toLowerCase();
		var plat = navigator.platform;
		var device = ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i) ? ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i)[0] : false;

		if(device == 'iphone' || device == 'ipad' || device == 'ipod'){
			function iOSversion() {
					
				if (/iP(hone|od|ad)/.test(navigator.platform)) {
				  var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
				  return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
				}
			}

			var ver = iOSversion() || [0];
			if (ver[0] >= 6) {
				protocol = 'apple';
			}
		}
		
		var urlSuffix = '/?daddr='+address;
		var nav_url = '';
		switch(protocol) {
			case 'apple':
				nav_url = 'maps://';
				break;
			default:
				nav_url = 'https://maps.google.com/maps';
				break;
		}
		item.attr('target', '_blank');
		item.attr('href', nav_url+urlSuffix);
	}
}


function openGallery(item) {
	var groupId = item.data('group-id');
	var index = item.data('index');
	
	if (typeof galleryItems === 'object' && typeof galleryItems[groupId] !== 'undefined') {
		if (!index) {
			index = 0;
		}

		initGallery(item, galleryItems[groupId], index);
	} 
}

function openArticleGallery(item) {
	var groupId = $(item).data('gallery-id');
	if (groupId && typeof articleGalleryItems === 'object' && typeof articleGalleryItems[groupId] !== 'undefined') {
		initGallery(item, articleGalleryItems[groupId]);
	}
}

function initGallery(target, items, index) 
{
	if (!index) {
		index = 0;
	}
	
	$(target).lightGallery({
		dynamic:true,
		dynamicEl:items,
		index:index,
		hash:false,
		download: false
	});
}

function getMapStyle()
{
	return [];
}

function initContactMap() {
	if ($('#contactMap').length > 0) {
		if (mapboxgl.supported() && typeof storeCoordinates !== 'undefined') {
			var centerLat = storeCoordinates.length == 1 ? storeCoordinates[0].lat : 47.531248;
			var centerLng = storeCoordinates.length == 1 ? storeCoordinates[0].lng : 19.071062;

			mapboxgl.accessToken = 'pk.eyJ1IjoiY3pha29nIiwiYSI6ImNqdDRkbm1ueTEwMnU0OXBnMGdnM2w2emcifQ.SZk5BdRhDx1XDLivBIOQwA';
			swanMedMap = new mapboxgl.Map({
				container: 'contactMap',
				style: 'mapbox://styles/czakog/cjxcyzth80op01co30598f2ir',
				zoom: 16,
				center:[centerLng, centerLat]
			});
			
			
			var min_lng = 100;
			var max_lng = 0;
			var min_lat = 100;
			var max_lat = 0;
			
			swanMedMap.on('load', function() {
				for (var i = 0; i < storeCoordinates.length; i++) {
					var obj = storeCoordinates[i];
					if (typeof obj === 'object' && typeof obj.lat !== 'undefined' && typeof obj.lng !== 'undefined') {
						var el = document.createElement('div');
						el.className = 'map-marker';
						el.innerHTML = '<span class="marker-outer" title="'+obj.name+'">'+svg('marker-icon')+'</span>';
						new mapboxgl.Marker(el).setLngLat([obj.lng, obj.lat]).addTo(swanMedMap);
						var lat = parseFloat(obj.lat);
						var lng = parseFloat(obj.lng);
						
						if (lng < min_lng) {
							min_lng = lng;
						}

						if (lng > max_lng) {
							max_lng = lng;
						}

						if (lat < min_lat) {
							min_lat = lat;
						}

						if (lat > max_lat) {
							max_lat = lat;
						}
					}
				}
				
				if (storeCoordinates.length > 1) {
					swanMedMap.fitBounds([[min_lng, min_lat], [max_lng, max_lat]], {
						padding:24
					});
				}
			});
			
		}
	}
}

function openVideoRemodal(videoId) {
	var popup = $('[data-remodal-id="videoRemodal"]');
	if (popup.length > 0 && videoId) {
		var videoUrl = 'https://www.youtube.com/embed/'+videoId+'?autoplay=1';

		var frame = $('<iframe>', {
			'frameborder':'0',
			'allow':'autoplay; encrypted-media',
			'allowfullscreen':'',
			'src':videoUrl
		});
		$('#videoPopupContent').html('');
		$('#videoPopupContent').append(frame);
		
		popup.remodal().open();
	}
}

$(document).on('closed', '.remodal', function (e) {
	var target = $(e.target);
	if (target.attr('id') == 'videoRemodal') {
		$('#videoPopupContent').html('');
	}
});

function initSlider()
{
	var touchDevice = false;
	if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/)) {
		touchDevice = true;
	}
	headerSlider = $('#headerSlider').bxSlider({
		auto:true,
		pause:20000,
		infiniteLoop: true,
		touchEnabled: touchDevice,
		preventDefaultSwipeX: true,
		preventDefaultSwipeY: false,
	});
}