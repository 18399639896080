(function(window){
	function articleTags(target, params) {
        try {
            if (params) {
                this.setParams(params);
            }
    
            if (target) {
                this.setTarget(target);
			}
			
            this.init();    
        } catch (error) {
            console.error(error);
        }
	}

	articleTags.prototype = {
		target: undefined,
		container: undefined,
		archive_first: false,
		tags:[],
		archives:[],
		selected:[],
		texts:{
			title: 'Bejegyzések szűrése',
			tag_title: 'Címkék',
			archive_title: 'Archívum'
		},
		options:{
			tag_prefix: undefined,
			archive_prefix: undefined,
			page_url: undefined,
			ajax: false
		},
		opened:false,
        writeArchives: false,
		init: function(){
			this.createDOM();
			this.addDOMEvents();
		},
		createDOM: function(){
			this.container = this.createContainer();

			this.title = this.createTitle();
			this.close = this.createClose();
            this.tags_container = this.createTagsContainer();
            if (this.writeArchives) {
                this.archives_container = this.createArchivesContainer();
            }

			this.container.append( this.title );
			this.container.append( this.close );

            if (this.writeArchives) {
                if( this.isArchiveFirst() ){
                    this.container.append(this.archives_container);
                    this.container.append(this.tags_container);
                }else{
                    this.container.append(this.tags_container);
                    this.container.append(this.archives_container);
                }
            } else {
                this.container.append(this.tags_container);
            }

			this.container.appendTo( 'body' );

		},
		createContainer: function(){
			return $('<div>', {class:'article-tags-container'});
		},
		createTitle: function(){
			return $('<h2>', {class:'title h1', html:this.getText('title')});
		},
		createClose: function(){
			return $('<a>', {class:'close-btn', title:this.getText('close'), 'html':svg("close-icon")});
		},
		createTagsContainer: function(){
			var _self = this;

			var countTags = typeof this.tags == 'object' ? Object.keys(this.tags).length : this.tags.length;
			var content = null;
			if (countTags > 0) {

				content = $('<div>', {
					class: 'button-rows tags-row',
					html: $('<h3>', {
						html: _self.getText('tag_title')
					})
				});

				var inner = $('<div>', {
					class: 'inner'
				});


				var selectedTag = this.getSelected( 'tag' );
				if(typeof this.tags === 'object'){
					$.each(this.tags, function(key, tag){
						inner.append(_self.createTagField(tag, selectedTag));
					});
				}
				else if(this.tags.length > 0){

					for(var i = 0; i < this.tags.length; i++){
						inner.append( this.createTagField( this.tags[i], selectedTag ) );
					}
				}

				content.append(inner);
			}
			return content;
		},
		createTagField: function( tag, selectedTag ){
            var page_url = this.getPageUrl();
			return $('<a>', {
				class: 'tag article-tag '+( tag['selected'] == 1 ? ' selected' : ''),
				href: (tag['selected'] == 1 ? page_url.substring(0, (page_url.length - 1)) : (this.getPageUrl()+this.getTagPrefix()+'/'+tag['tag_url'])),
				html: tag['tag']
			});
		},
		createArchivesContainer: function(){
			var _self = this;
			var content = $('<div>', {
				class: 'button-rows archives-row',
				html: $('<h3>', {
					html: _self.getText('archive_title')
				})
			});

			if( Object.keys(this.archives).length > 0 ){

				for( var i = Object.keys(this.archives).length - 1; i >= 0; i--){
					tmp_year = Object.keys(this.archives)[i];
					content.append( _self.createArchiveRow( tmp_year, this.archives[ tmp_year] ) );
				}
			}

			return content;
		},
		createArchiveRow: function( year, months ){
			var content = $('<div>', {
				class: 'inner'
			});

			var selectedYear = this.getSelected( 'year' );

			var yearAnchor = $('<a>', {
				'html': year,
				'href': this.getPageUrl()+this.getArchivePrefix()+'/'+year,
				'class': 'year-link archive-link'+(year == selectedYear ? ' selected' : '')
			});

			content.append(yearAnchor);
			if( months.length > 0 ){
				var month_names = this.getText('months');
				var selectedMonth = year == selectedYear ? this.getSelected( 'month' ) : 0;
				for( var i = 0; i < months.length; i++ ){
					var tmp = $('<a>', {
						'class': 'months-link archive-link'+(months[i] == selectedMonth ? ' selected' : ''),
						'html': typeof month_names[months[i] - 1] != 'undefined' ? month_names[months[i] - 1] : months[i],
						'href': this.getPageUrl()+this.getArchivePrefix()+'/'+year+'/'+months[i]
					});

					content.append(tmp);
				}
			}

			return content;
		},
		addDOMEvents: function(){
			var _self = this;
			if( this.target ){
				this.target.click(function(){
					_self.changeContainerVisibility();
				});
			}
			if( this.close ){
				this.close.click(function(){
					_self.changeContainerVisibility();
				});
			}
		},
		changeContainerVisibility: function(){
			if( !this.isOpened() ){
				this.setOpened( true );
				$('body').addClass('opened-article-tags');
				this.container.addClass('opened');
			}else{
				this.setOpened( false );
				$('body').removeClass('opened-article-tags');
				this.container.removeClass('opened');
			}
		},
		setTarget: function( target ){
			this.target = target;
		},
		setOpened: function( opened ){
			this.opened = opened;
		},
		setParams: function( params ){
			if( params.texts ) this.setTexts( params.texts );
			if( params.tags ) this.setTags( params.tags );
			if( params.archives ) this.setArchives( params.archives );
			if( params.page_url ) this.setOptions( 'page_url', params.page_url);
			if( params.tag_prefix ) this.setOptions( 'tag_prefix', params.tag_prefix);
			if( params.archive_prefix ) this.setOptions( 'archive_prefix', params.archive_prefix);
			if( params.selected ) this.setSelected( params.selected );
			if( params.archive_first ) this.setArchiveFirst( params.archive_first );
		},
		setTexts: function( texts ){
			this.texts = jQuery.extend({}, this.texts, texts);
		},
		setTags: function( tags ){
			this.tags = tags;
		},
		setArchives: function( archives ){
			this.archives = archives;
		},
		setOptions: function( key, value ){
			if(key == 'page_url' && value[value.length - 1] !== '/'){
				value += '/';
			}
			this.options[key] = value;
		},
		setSelected: function( selected ){
			this.selected = selected;
		},
		setArchiveFirst: function( archive_first ){
			this.archive_first = archive_first;
		},
		getText: function( exp ){
			return typeof this.texts[exp] != 'undefined' ? this.texts[exp] : exp;
		},
		getTags: function(){
			return this.tags;
		},
		getArchives: function(){
			return this.archives;
		},
		getOptions: function( key ){
			return typeof this.options[key] != 'undefined' ? this.options[key] : null;
		},
		getTagPrefix: function(){
			return this.getOptions( 'tag_prefix' );
		},
		getArchivePrefix: function(){
			return this.getOptions( 'archive_prefix' );
		},
		getPageUrl: function(){
			return this.getOptions( 'page_url' );
		},
		getSelected: function( key ){
			if(key)
				return typeof this.selected[key] != 'undefined' ? this.selected[key] : null;

			return this.selected;
		},
		isOpened: function(){
			return this.opened;
		},
		isArchiveFirst: function(){
			return this.archive_first;
		}
	};

	window.articleTags = articleTags;
})(window);
