(function(window){
    function ProductPage(container, params) {
        try {
            if (!container) {
                throw 'Container is required!';
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    ProductPage.prototype = {
        products:[],
        listContainer:undefined,
        tabs:undefined,
        container:undefined,
        selectedGroup: 'all',
        remodal:undefined,
        hasProductForm: false,
        init: function() {
            this.setElements();
            this.addDOMEvents();
            this.addProductDOMEvents();
            // this.writeProducts();
        },
        setElements: function() {
            this.tabs = this.container.find('[data-purpose="product-group-tab"]');
            this.listContainer = this.container.find('[data-purpose="product-list"]');
            this.remodal = $('.contact-remodal');
        },
        addDOMEvents: function() {
            var _self = this;

            if (this.tabs && this.tabs.length > 0) {
                this.tabs.click(function(){
                    var group = $(this).data('type');
                    if (group != _self.selectedGroup) {
                        _self.tabs.removeClass('selected');
                        $(this).addClass('selected');
                        _self.selectedGroup = group;
                        if (_self.hasProductForm) {
                            _self.destroyProductDOMEvents();
                        }
                        _self.writeProducts();

                        if (_self.hasProductForm) { 
                            _self.addProductDOMEvents();
                        }
                    }
                });
            }

            
        },
        addProductDOMEvents: function() {
            var _self = this;
            $('.product-cta').click(function(){
                _self.productClick(this);
            });
        },
        destroyProductDOMEvents: function() {
            $('.product-cta').off('click');
        },
        productClick: function(button) {
            if (this.remodal.find('#productInner').hasClass('success-sent')) {
                this.remodal.find('#productInner').removeClass('success-sent');
            }

            var hiddenProduct = this.remodal.find('.product-name');        
            
            hiddenProduct.val($(button).data('product-name'));
    
            this.remodal.remodal().open();
        },
        writeProducts: function() {
            this.listContainer.html('');
            if (this.products.length > 0) {
                for (var i = 0; i < this.products.length; i++) {
                    var product = this.products[i];
                    if (this.selectedGroup == 'all' || (product.groups).indexOf(this.selectedGroup) >= 0) {
                        this.listContainer.append(this.createProductContent(product));
                    }
                }
            }
        },
        createProductContent: function(product) {
            var content = $('<div>', {
                class: 'product-box '+(this.hasProductForm ? 'has-button' : '')
            });

            var inner = $('<div>', {
                class: 'inner'
            });
            
            inner.append('<div class="image-content"><img src="'+product.src+'" alt="'+product.name+'" /></div>');
            var textContent = $('<div>', {
                'class': 'text-content'
            });

            textContent.append('<div class="name">'+product.name+'</div>');
            textContent.append('<div class="description">'+product.description+'</div>');

            inner.append(textContent);
            if (this.hasProductForm) {
                var buttonContent = $('<div>', {
                    class: 'button-content'
                });


                buttonContent.append('<a href="javascript:void(0);" data-product-name="'+product.name+'" class="button product-cta small primary"><span class="text">Érdeklődöm</span><span class="icon-outer"><span class="svg"><svg class="icon right-arrow "><use xlink:href="#right-arrow"></use></svg></span></span></a>');

                inner.append(buttonContent);
            }
            content.append(inner);

            return content;
        },
        setParams: function(params) {
            if (params.products) this.setProducts(params.products);
            if (typeof params.hasProductForm !== 'undefined') this.setHasProductForm(params.hasProductForm);
        },
        setProducts: function(products) {
            this.products = products;
        },
        setHasProductForm: function(value) {
            this.hasProductForm = value;
        }
    }

    window.ProductPage = ProductPage;
})(window);
